import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FullTestimonials extends React.Component {
  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => this.slider.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => this.slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };
  
  render() {
    return (
      <section
        className={`testimonials ${
          this.props.withIMG
            ? "section-padding bg-img"
            : this.props.withCOLOR
            ? "section-padding back-color"
            : this.props.noPadding ? ""
            : "section-padding"
        } ${this.props.classText ? this.props.classText : ""}`}
        style={{
          backgroundImage: `${
            this.props.withIMG ? "url(/img/testim-img.jpg)" : "none"
          }`,
        }}
      >
        {this.props.showHead && (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="sec-head  text-center">
                  <h6 className="wow fadeIn" data-wow-delay=".5s">
                    Testimonials
                  </h6>
                  <h3 className="wow color-font">
                    We love our clients from all over the world.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid position-re">
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-lg-12">
              <Slider
                className="slic-item"
                {...{
                  ref: (c) => (this.slider = c),
                  dots: false,
                  infinite: true,
                  arrows: true,
                  centerMode: true,
                  autoplay: false,
                  rows: 1,
                  slidesToScroll: 1,
                  slidesToShow: 3,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                  ],
                }}
              >
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/mitre.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">
                          MITRE
                        </h6>
                        <span className="author-details">
                          Conference AV Support
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                  Expertly configured and deployed a diverse range of equipment, including PTZ
cameras, PTZ controllers, digital audio mixers, RF audio receivers, video switchers,
recording decks, light controllers, projection systems, multi-monitor displays and
supporting network infrastructure.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/canso.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">
                          CANSO / Infina
                        </h6>
                        <span className="author-details">
                          Lead Video Producer / Editor
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                  Specialized in curating extensive training media libraries tailored for a global audience, employing a diverse range of multimedia formats, including mixed media, 3D elements, and simulated graphics.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/sda.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">
                          Seventh-Day Adventist Church
                        </h6>
                        <span className="author-details">
                          AV Director
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                  Strategized, executed, and oversaw video and network projects
from inception to completion. Expertly set up video and audio systems for seminars, live streams, zoom meetings,
skype calls, integrating broadcast software for optimal audio management.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <div className="img">
                          <img src="/img/clients/faa.jpg" alt="" />
                        </div>
                        <h6 className="author-name color-font">
                          Federal Aviation Administration
                        </h6>
                        <span className="author-details">
                          Various Contracts
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                  Offered technical leadership for live events, conferences, live streams, zoom events
and public-facing occasions, coordinating seamless logistics for equipment
transportation via air cargo across USA/LATAM.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
      </section>
    );
  }
}

export default FullTestimonials;
