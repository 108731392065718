import React from "react";
import { Link } from 'gatsby';

const Services2 = () => {
  return (
    <section className="services section-padding position-re">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                STUDIO OPERATIONS
              </h6>
              <h3 className="wow color-font">
              It's all about controlling the behavior of light and sound waves within a defined time and space.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".3s">
            <div className="step-item xtop">
              <span className="icon pe-7s-keypad"></span>
              <h6>Technical Operations</h6>
              <p>
                Expert in camera operation, microphone selection and placement, and scene lighting techniques.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
            <div className="step-item xmiddle">
              <span className="icon pe-7s-look"></span>
              <h6>Filming &amp; Set Management</h6>
              <p>
                Expert in adapting sets and staging. Directing camera selections and crew control.
              </p>
            </div>
          </div>
          <div className="col-lg-4 wow fadeInUp" data-wow-delay=".9s">
            <div className="step-item xbottom">
              <span className="icon pe-7s-magic-wand"></span>
              <h6>Post-Production &amp; Support</h6>
              <p>
                Expert in editing, color correction and audio mastering. Signal Flow for live broadcasts.
              </p>
            </div>
          </div>
        </div>
    
      </div>
      <div className="line top left"></div>
      <div className="line bottom right"></div>
    </section>
  );
};

export default Services2;
