import React from "react";
import Navbar from "components/Navbar/navbar";
import IntroStudio from "components/Intro3/introStudio";
import Footer from "components/Footer/footer";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import Services2 from "components/Services2/services2";
import Works2 from "components/Works2/works2";
import Numbers2 from "components/Numbers2/numbers2";
import DarkTheme from "layouts/Dark";

const Studio = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <IntroStudio />
      <Services2 style="4item" />
      <Works2 />
      <Numbers2 />
      <FullTestimonials withIMG />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>David Leiva - Studio Work</title>
    </>
  )
}

export default Studio;
