import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

SwiperCore.use([Navigation]);

const Works2 = ({ sliderRef }) => {
  const [load, setLoad] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    });
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <section className="work-carousel section-padding caroul position-re pb-0">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  Studio Components
                </h6>
                <h3 className="wow color-font">
                  Some of my favorite AV &amp; <br /> Studio components.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 no-padding">
              <div className="swiper-container">
                <div className="swiper-container ">
                  {!load ? (
                    <Swiper
                      speed={1000}
                      loop={true}
                      spaceBetween={0}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        767: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        991: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        1024: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        1180: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        1650: {
                          slidesPerView: 4,
                          spaceBetween: 0,
                        },
                      }}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl =
                          navigationPrevRef.current;
                        swiper.params.navigation.nextEl =
                          navigationNextRef.current;
                      }}
                      onSwiper={(swiper) => {
                        setTimeout(() => {
                          swiper.params.navigation.prevEl =
                            navigationPrevRef.current;
                          swiper.params.navigation.nextEl =
                            navigationNextRef.current;

                          swiper.navigation.destroy();
                          swiper.navigation.init();
                          swiper.navigation.update();
                        });
                      }}
                      className="swiper-wrapper"
                      slidesPerView={1}
                    >
                      <SwiperSlide className="swiper-slide">
                        <div
                          className="content wow fadeInUp"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="item-img bg-img wow imago"
                            style={{
                              backgroundImage: "url(/img/portfolio/curs/1.jpg)",
                            }}
                          ></div>
                          <div className="cont bgbox">
                            <h6>
                              
                              
                                Chroma-Key &amp; Real-time Virtual Scene
                             
                            </h6>
                            <h4>
                              
                              
                                Experienced in cutting edge background replacement technologies and techniques. From green fabric to LED walls. Adapting to a wide range of budgets and constraints.
                              
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div
                          className="content wow fadeInUp"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="item-img bg-img wow imago"
                            style={{
                              backgroundImage: "url(/img/portfolio/curs/2.jpg)",
                            }}
                          ></div>
                          <div className="cont bgbox">
                            <h6>
                             
                                TELEPROMPTER
                              
                            </h6>
                            <h4>
                              The market has exploded with a variety and size of teleprompters, making this tool virtually accessible to everyone, the difficult task is controlling the eye movement and look space.
                            
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div
                          className="content wow fadeInUp"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="item-img bg-img wow imago"
                            style={{
                              backgroundImage: "url(/img/portfolio/curs/4.jpg)",
                            }}
                          ></div>
                          <div className="cont bgbox">
                            <h6>
                              
                                VIDEO SWITCHER, ROUTING &amp; Distribution
                              
                            </h6>
                            <h4>
                              
                                
In 2006, I was first introduced to an analog video switcher. Since then, technology has advanced dramatically, and prices have become more affordable. Over the years, I have gained extensive expertise in Blackmagic Design's line of switchers, videohubs, and other studio equipment. With exposure to various video processors such as Barco's E2 Gen 2 and Analog Way's Zenith 200.
                            
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div
                          className="content wow fadeInUp"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="item-img bg-img wow imago"
                            style={{
                              backgroundImage: "url(/img/portfolio/curs/3.jpg)",
                            }}
                          ></div>
                          <div className="cont bgbox">
                            <h6>
                              
                                Lighting &amp; Power
                             
                            </h6>
                            <h4>
                             
                                A fundamental understanding of Ohm's law is crucial for safe and efficient productions. It enables me to power light fixtures without the risk of overloading breakers, ensuring a secure and bright operation.
                            
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div
                          className="content wow fadeInUp"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="item-img bg-img wow imago"
                            style={{
                              backgroundImage: "url(/img/portfolio/curs/5.jpg)",
                            }}
                          ></div>
                          <div className="cont bgbox">
                            
                            <h6>
                             
                                Audio Mastering &amp; Microphone Selection
                             
                            </h6>
                            <h4>
                              
                            In 2001, I was first introduced to a Mackie mixing console, and I immediately fell in love with the concept of audio as a signal. The endless possibilities to enhance and manipulate that signal fascinated me, opening up a world of creativity and technical exploration.
                              
                            </h4>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  ) : null}

                  <div
                    ref={navigationNextRef}
                    className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
                  >
                    <i className="ion-ios-arrow-right"></i>
                  </div>
                  <div
                    ref={navigationPrevRef}
                    className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
                  >
                    <i className="ion-ios-arrow-left"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Works2;
