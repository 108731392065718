import React from "react";
import Split from "../Split";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Numbers2 = () => {
  React.useEffect(() => {
    console.clear();
  });
  
  return (
    <section className="block-sec section-padding">
      <div className="container">

      <div className="cont text-center section-padding">
              <h1>
                <span className="color-font">Hands on Experience</span> 
              </h1>
                <h3>Some highlights from my studio experience</h3>
               
            </div>
        <div className="number-sec">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb50">
                <span className="icon pe-7s-film"></span>
                <h3 className="custom-font">
                  &nbsp;
                  <CountUp redraw={true} end={8} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  K
                </h3>
                <Split>
                  <p className="wow words chars splitting txt" data-splitting>
                    Is the maximum Resolution I have worked on for a single Program Output
                  </p>
                </Split>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb50">
                <span className="icon pe-7s-upload"></span>
                <h3 className="custom-font">
                  &nbsp;
                  <CountUp redraw={true} end={16} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </h3>
                <Split>
                  <p className="wow txt words chars splitting " data-splitting>
                    Control and Switching of Video Input Sources
                  </p>
                </Split>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item sm-mb50">
                <span className="icon pe-7s-headphones"></span>
                <h3 className="custom-font">
                  &nbsp;
                  <CountUp redraw={true} end={100} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </h3>
                <Split>
                  <p className="wow txt words chars splitting" data-splitting>
                    Studio Productions Serving as Technical Director
                  </p>
                </Split>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <span className="icon pe-7s-edit"></span>
                <h3 className="custom-font">
                  &nbsp;
                  <CountUp redraw={true} end={32} duration="3">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span className="count" ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </h3>
                <Split>
                  <p className="wow txt words chars splitting" data-splitting>
                    Audio Sources and Level Mastering for Studio Music Recording.
                  </p>
                </Split>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers2;
