import React from "react";

const IntroStudio = () => {
  return (
    <header className="slider-stwo valign position-re">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="img">
              <img src="/img/slid/002.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-7 valign">
            <div className="cont">
              <div className="sub-title mb-5">
                <h6>STUDIO EXPERIENCE</h6>
              </div>
              <h1 className="mb-10 fw-600">Lights, Sound, Camera, Action!</h1>
              <p>
               
I have worked in a wide range of studio environments throughout my career, from home studios to aircraft showroom hangars.
<br/> Each experience has taught me valuable lessons about what makes a studio functional and how to enhance its usability to meet versatile production demands. 
              </p>
              <ul>
                <li>
                  <div>
                    <span className="icon pe-7s-tools">
                      <span className="bord"></span>
                    </span>
                  </div>
                  <div className="cont">
                    <h6>Design | Install</h6>
                    <p>
                    I have designed and installed studios for houses of worship, individual content creators, and media agencies. 
                   Collaborating with architects, integrators, and electricians, with the goal to ensure the best possible outcomes for every project.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <span className="icon pe-7s-video">
                      <span className="bord"></span>
                    </span>
                  </div>
                  <div className="cont">
                    <h6>Maintain | Operate</h6>
                    <p>
                    I have provided maintenance and upgrades for studio equipment and IT infrastructure. With countless hours spent on studio video production, audio recording, and photography, I can confidently say that studio work is my most comfortable and favorite environment for creating content.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default IntroStudio;
